import { ReplaceableComponentsService } from '@abp/ng.core';
import { EmptyLayoutComponent, eThemeBasicComponents } from '@abp/ng.theme.basic';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { GlobalStateService } from './@core/services/global-state.service';

@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar [color]="'#ff8888'"></abp-loader-bar>
    <app-spinner> </app-spinner>
    <abp-dynamic-layout> </abp-dynamic-layout>
  `,
})

export class AppComponent implements OnInit {
  constructor(
    private replaceableComponents: ReplaceableComponentsService,
    private router: Router,
    private readonly nzConfigService: NzConfigService,
    private globalStateService: GlobalStateService
  ) {
    this.nzConfigService.set('notification', {
      nzAnimate: true,
      nzDuration: 4000,
      nzPauseOnHover: true,
    });
    this.getLayout(EmptyLayoutComponent);
  }
  ngOnInit(): void {
    this.globalStateService.getGlobalState().subscribe(); // get current state
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


  public getLayout(replaceableComponent) {
    this.replaceableComponents.add({
      component: replaceableComponent,
      key: eThemeBasicComponents.ApplicationLayout,
    });
  }



}
