import { ExtendedEnvironment } from './environment.interface';

const baseUrl = 'https://ux.projotech.com';

export const environment = {
  production: true,
  defaultTheme : 'theme5',
  application: {
    baseUrl,
    name: 'Protopia',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://api.projotech.com',
    redirectUri: baseUrl,
    clientId: 'Protopia_App',
    responseType: 'code',
    scope: 'offline_access openid profile role email phone Protopia',
    requireHttps: true
  },
  apis: {
    default: {
      url: 'https://api.projotech.com',
      rootNamespace: 'Protopia',
    },
  },
  firebaseConfig : {
    apiKey: "AIzaSyBB-9oqjl1z4x1TjB1jCS6ymIk_7QXFl_g",
    authDomain: "projotech-prod.firebaseapp.com",
    projectId: "projotech-prod",
    storageBucket: "projotech-prod.appspot.com",
    messagingSenderId: "383806570095",
    appId: "1:383806570095:web:92f2baafbda04c012c179a",
    vapidKey : "BGLMdpOIbEuyzFBuCCdouMD_9RMQGy0AznCng9r38Z_1ajyxc4-mYPs7ITz1NGLZ3je_mN90Bnak4ZKallwRXVY"
  }
} as ExtendedEnvironment;
