import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { DepartmentDto } from '@proxy/departments';
import { ProjectPublishDto } from '@proxy/projects/ideation';
import { GlobalStateDto } from '@proxy/university';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService {
  constructor(private restService: RestService) {
    this.globalState$ = this.globalStateSubject.asObservable();
  }
  apiName = 'Default';
  globalState: GlobalStateDto;
  globalStateSubject: BehaviorSubject<GlobalStateDto> = new BehaviorSubject(null);
  globalState$: Observable<GlobalStateDto>;
  department$ : Observable<DepartmentDto>;
  // call only if need update on request
  getGlobalState = () =>
    this.restService
      .request<any, GlobalStateDto>(
        {
          method: 'GET',
          url: '/api/app/university/global-state',
        },
        { apiName: this.apiName, skipHandleError: true }
      )
      .pipe(
        map(result => {
          this.globalState = result;
          this.globalStateSubject.next(this.globalState);
        })
      );

  queuePublish = (input: ProjectPublishDto) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/project-ideation-ideation/queue-publish',
      body: input,
    },
      { apiName: this.apiName, skipHandleError: true });

  triggerTeamFormation = () => {
    return this.restService
      .request<any, any>(
        {
          method: 'PUT',
          url: '/api/app/team-formation/trigger-team-formation',
        },
        { apiName: this.apiName, skipHandleError: true }
      );
  }
}
